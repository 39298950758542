<template>
  <section class="box-height" style="margin-bottom: 70px">
    <div class="box-title" style="border: 1px #e5e5e5 solid">
      <el-row :gutter="24">
        <el-col :span="23">
          <el-cascader
            :options="options"
            :show-all-levels="false"
            @visible-change="search"
            :props="props1"
            v-model="searchVal"
            placeholder="筛选"
            class="seleKey"
            @change="changeValue($event)"
            v-show="activeName == 2"
            size="mini"
            clearable
          ></el-cascader>
        </el-col>
        <el-col :span="24" class="resumeTag">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="待处理" name="1">
              <el-row>
                <el-form :model="filter" ref="filter">
                  <el-form-item label="模块：">
                    <el-select
                      size="mini"
                      v-model="filter.model"
                      filterable
                      style="width: 240px"
                      clearable
                    >
                      <el-option
                        v-for="item in modelList"
                        :key="item"
                        :value="item"
                        :label="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="待匹配字段：" style="margin-left: 20px">
                    <el-input
                      size="mini"
                      v-model="filter.name"
                      clearable
                      placeholder="支持模糊查询"
                      style="width: 240px"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label=""
                    label-width="0"
                    style="margin-left: 20px"
                  >
                    <el-button type="primary" size="small" @click="serchButton"
                      >搜索</el-button
                    >
                  </el-form-item>
                </el-form>
              </el-row>
              <!--              待处理-->
              <el-row>
                <el-col style="display: flex; justify-content: space-between">
                  <div>
                    <el-button
                      size="mini"
                      @click="handleResumeBatchMatch"
                      style="margin-left: 10px"
                      :disabled="itemIds.length == 0"
                      >批量匹配</el-button
                    >
                    <el-button
                      size="mini"
                      @click="deletMatch"
                      style="margin-left: 10px"
                      :disabled="itemIds.length == 0"
                      >批量删除</el-button
                    >
                  </div>
                  <el-pagination
                    :hide-on-single-page="pagination.total > 10 ? false : true"
                    v-if="pagination.total > 0"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pagination.pageNo"
                    :page-sizes="[10, 50, 100, 500, 1000]"
                    :pager-count="5"
                    :page-size="pagination.pageSize"
                    layout="total, prev, pager, next, sizes"
                    :total="pagination.total"
                  ></el-pagination>
                </el-col>
              </el-row>
              <el-table
                :data="ListData"
                class="tabBorder custor"
                :header-row-style="{ height: '36px', margin: '0' }"
                :header-cell-style="{
                  'background-color': '#f5f7fa',
                }"
                v-loading="listLoading"
                ref="handSelectTest_multipleTable"
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  type="selection"
                  width="40"
                  align="center"
                ></el-table-column>
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop="module"
                  label="模块"
                  align="left"
                ></el-table-column>
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop="matchValue"
                  label="待匹配字段"
                  align="left"
                >
                  <template slot-scope="scope">
                    <span class="tabHref" @click="showDtialDig(scope.row)">{{
                      scope.row.matchValue
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop="createdTime"
                  label="创建时间"
                  align="left"
                ></el-table-column>
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop
                  label="操作"
                  align="left"
                  width="200px"
                >
                  <template slot-scope="scope">
                    <span class="tabHref" @click="handleMatch(scope.row)"
                      >匹配</span
                    >
                    <el-divider direction="vertical"></el-divider>
                    <span class="tabHref" @click="deletMatch(scope.row)"
                      >删除</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="已处理" name="2">
              <!--              已处理-->
              <el-pagination
                :hide-on-single-page="pagination.total > 10 ? false : true"
                v-if="pagination.total > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pagination.pageNo"
                :page-sizes="[10, 20, 50, 100]"
                :pager-count="5"
                :page-size="pagination.pageSize"
                layout="total, prev, pager, next, sizes"
                :total="pagination.total"
              ></el-pagination>
              <el-table
                :data="ListDataed"
                class="tabBorder custor"
                :header-row-style="{ height: '36px', margin: '0' }"
                :header-cell-style="{
                  'background-color': '#f5f7fa',
                }"
                v-loading="listLoading"
              >
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop="module"
                  label="模块"
                  align="left"
                ></el-table-column>
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop="systemValue"
                  label="系统字段"
                  align="left"
                ></el-table-column>
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop="matchedValues"
                  label="已配置匹配项"
                  align="left"
                ></el-table-column>
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop="lastModifiedTime"
                  label="最后编辑时间"
                  align="left"
                ></el-table-column>
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop
                  label="操作"
                  align="left"
                  width="200px"
                >
                  <template slot-scope="scope">
                    <span class="tabHref" @click="handleEdit(scope.row)"
                      >编辑</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination.pageSize"
            layout="total, prev, pager, next, sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
      </el-row>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      append-to-body
      class="matchDialog"
      title="匹配字段"
      :close-on-click-modal="false"
      width="600px"
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="footer" class="footer-title clearfix">
        <span>
          <el-button type="default" size="mini" @click="handleClose"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="save('createDate')"
            >保存</el-button
          >
        </span>
      </div>
      <el-form
        @submit.native.prevent
        :model="createDate"
        ref="createDate"
        label-width="120px"
        v-if="isMatch"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="待匹配字段：" prop="name">
              <el-input
                v-model="matchV"
                disabled
                placeholder="请不要输入超过40个字"
                maxlength="40"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="匹配系统字段：" class="is-required">
              <el-cascader
                :options="matchoptions"
                :show-all-levels="false"
                :props="props"
                v-model="systemValueSel"
                style="width: 100%"
                clearable
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form
        @submit.native.prevent
        :model="createEditDate"
        ref="createEditDate"
        style="padding-left: 40px"
        label-width="90px"
        v-if="isEdit"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="系统字段：" prop="name">
              <el-input
                v-model="matchV"
                disabled
                placeholder="请不要输入超过40个字"
                maxlength="40"
                style="width: 350px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="Values.length == 0">
            <el-form-item label="新增匹配项：" prop="name">
              <span
                style="margin-right: 20px; color: #526ecc; cursor: pointer"
                @click="addNew"
              >
                <i class="el-icon-plus"></i> 新增
              </span>
            </el-form-item>
          </el-col>
          <div v-else>
            <el-col
              :span="24"
              class="matchList"
              v-for="(item, index) in Values"
              :key="index"
            >
              <el-form-item label="新增匹配项：" prop="name">
                <el-input v-model="item.name" style="width: 350px"></el-input>
                <i class="el-icon-plus" @click="addNew"></i>
                <i class="el-icon-close" @click="del(index)"></i>
              </el-form-item>
            </el-col>
          </div>
        </el-row>
      </el-form>
      <el-form
        @submit.native.prevent
        :model="createDate"
        ref="createDate1"
        label-width="120px"
        v-if="isallMatch"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="匹配系统字段：" class="is-required">
              <el-cascader
                :options="options"
                :show-all-levels="false"
                :props="props1"
                v-model="systemValueSel1"
                @expand-change="getfater"
                style="width: 100%"
                clearable
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible1"
      :modal-append-to-body="false"
      append-to-body
      class="matchDialog"
      title="简历"
      :close-on-click-modal="false"
      width="600px"
      :before-close="handleClose"
      destroy-on-close
    >
      <div>
        <p>
          <span>模块：{{ resumeDataMsg.moduleName }}</span
          ><span style="margin-left: 10px"
            >待匹配字段：{{ resumeDataMsg.matchValue }}</span
          >
        </p>
        <el-table
          :data="resumeData"
          class="tabBorder custor"
          :header-row-style="{ height: '36px', margin: '0' }"
          :header-cell-style="{
            'background-color': '#f5f7fa',
          }"
          v-loading="listLoading"
        >
          <el-table-column
            :show-overflow-tooltip="true"
            prop="resumeId"
            label="简历ID"
            align="left"
          >
            <template slot-scope="scope">
              <span
                class="tabHref"
                @click="showReasumDig(scope.row.resumeId)"
                >{{ scope.row.resumeId }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="candidateName"
            label="候选人"
            align="left"
          >
            <template slot-scope="scope">
              <span
                class="tabHref"
                @click="showReasumDig(scope.row.resumeId)"
                >{{ scope.row.candidateName }}</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose1"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-rc_bt"></i>
        人才
      </div>
      <div class="drawerCon">
        <resumeDetails
          v-if="drawerVisible"
          :resumeDetailId="resumeDetailId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
  </section>
</template>
<script>
import {
  resumeValueMatchlist,
  resumeValueMatchmatch,
  resumeValueMatchedit,
  getCommonColumnList,
  cityTree,
  resumeBatchMatch,
  deleteResumeValueMatch, //删除人才字段匹配
  deleteResumeValueMatchResumes, //待匹配字段管理人选
} from "../../api/api";
import resumeDetails from "../../components/resumeDetail";
export default {
  components: { resumeDetails },
  data() {
    return {
      filter: {
        model: "",
        name: "",
      },
      ListData: [], //待处理
      modelList: ["城市", "求职状态", "行业", "职能", "学历"],
      ListDataed: [], //已处理
      // visible: false,
      searchVal: "",
      titName: "",
      matchV: "", //待匹配字段
      saveloading: false,
      listLoading: false,
      dialogVisible: false,
      isEdit: false,
      isMatch: false,
      systemValueSel: [], //选中的匹配
      createDate: {
        id: "",
        systemValue: "",
      },
      Values: [],
      createEditDate: {
        module: "",
        systemValue: "",
        matchedValues: [],
      },
      options: [
        {
          name: "求职状态",
          disabled: true,
          nodisabled: false,
          children: [
            {
              name: "在职看机会",
            },
            {
              name: "在职不看机会",
            },
            {
              name: "离职",
            },
            {
              name: "应届毕业生",
            },
          ],
        },
        {
          name: "学历",
          disabled: true,
          nodisabled: false,
          children: [
            {
              name: "初中",
            },
            {
              name: "高中",
            },
            {
              name: "中技",
            },
            {
              name: "中专",
            },
            {
              name: "大专",
            },
            {
              name: "本科",
            },
            {
              name: "硕士",
            },
            {
              name: "MBA",
            },
            {
              name: "博士",
            },
            {
              name: "博士后",
            },
          ],
        },
        {
          name: "城市",
          disabled: true,
          nodisabled: false,
          children: [],
        },
        {
          name: "行业",
          disabled: true,
          nodisabled: false,
          children: [],
        },
        {
          name: "职能",
          disabled: true,
          nodisabled: false,
          children: [],
        },
      ],
      systemValue: "",
      activeName: "1",
      props: {
        value: "name",
        label: "name",
        children: "children",
        // disabled: "disabled",
        checkStrictly: false,
      },
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      itemIds: [],
      isallMatch: false,
      props1: {
        value: "name",
        label: "name",
        children: "children",
        disabled: "disabled",
        checkStrictly: true,
      },
      systemValueSel1: [], //选中的匹配
      matchValue: null,
      module: "",
      matchoptions: [],
      dialogVisible1: false,
      resumeData: [],
      resumeDataMsg: {},
      drawerVisible: false,
      repeatResume: { type: "列表", valId: "" },
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      resumeDetailId: null,
    };
  },
  methods: {
    // 搜索
    search() {
      this.options.map((i) => {
        i.disabled = true;
      });
      if (this.options[0].name != "全部") {
        this.options.unshift({
          name: "全部",
        });
      }
    },
    changeValue(e) {
      if (this.searchVal[0] != "全部") {
        this.module = this.searchVal[0];
        this.systemValue = this.searchVal[this.searchVal.length - 1];
      } else {
        this.module = "";
        this.systemValue = "";
      }
      this.loadList(true);
    },
    addNew() {
      this.Values.push({
        name: "",
      });
    },
    del(index) {
      this.Values.splice(index, 1);
    },
    handleClick(tab, event) {
      this.activeName = tab.name;
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 50;
      if (this.activeName == "1") {
        this.module = "";
        this.systemValue = "";
        this.loadList(false);
        this.props.checkStrictly = false;
      }
      if (this.activeName == "2") {
        this.loadList(true);
        this.props.checkStrictly = true;
      }
    },
    save(formName) {
      if (this.isMatch) {
        if (this.systemValueSel.length == 0) {
          this.$message.error("请选择匹配系统字段");
          return false;
        }
        this.createDate.systemValue =
          this.systemValueSel[this.systemValueSel.length - 1];
        this.saveloading = true;
        resumeValueMatchmatch(this.createDate).then((res) => {
          if (res.success) {
            this.$message.success("保存成功");
            this.dialogVisible = false;
            this.pagination.pageNo = 1;
            this.pagination.pageSize = 50;
            this.activeName = "1";
            this.systemValueSel = "";
            this.isMatch = false;
            this.loadList(false);
            this.handleClose();
          }
          this.saveloading = false;
          this.props.checkStrictly = false;
        });
      }
      if (this.isEdit) {
        if (this.Values.length == 0) {
          this.$message.error("请选择新增匹配项");
          return false;
        }
        this.createEditDate.matchedValues = [];
        this.Values.forEach((i) => {
          this.createEditDate.matchedValues.push(i.name);
        });
        resumeValueMatchedit(this.createEditDate).then((res) => {
          if (res.success) {
            this.$message.success("保存成功");
            this.pagination.pageNo = 1;
            this.pagination.pageSize = 50;
            this.dialogVisible = false;
            this.isEdit = false;
            this.activeName = "2";
            this.loadList(true);
          }
        });
      }
      if (this.isallMatch) {
        if (this.systemValueSel1.length == 0) {
          this.$message.error("请选择匹配系统字段");
          return false;
        }
        this.createDate.systemValue =
          this.systemValueSel1[this.systemValueSel1.length - 1];
        resumeBatchMatch({
          module: this.matchValue,
          ids: this.itemIds,
          systemValue: this.createDate.systemValue,
        }).then((res) => {
          if (res.success) {
            this.$message.success("保存成功");
            this.dialogVisible = false;
            this.pagination.pageNo = 1;
            this.pagination.pageSize = 50;
            this.isallMatch = false;
            this.activeName = "1";
            this.loadList(false);
          }
        });
      }
    },
    handleEdit(row) {
      this.dialogVisible = true;
      this.isEdit = true;
      this.matchV = row.module + " / " + row.systemValue;
      this.createEditDate.module = row.module;
      this.createEditDate.systemValue = row.systemValue;
      this.Values = [];
      row.matchedValuesArray.forEach((i) => {
        this.Values.push({ name: i });
      });
    },
    handleMatch(row) {
      this.matchV = row.matchValue;
      this.options.map((i) => {
        if (i.name == row.module) {
          i.disabled = false;
          this.matchoptions = i.children;
        } else {
          i.disabled = true;
        }
      });
      if (row.module == "城市") {
        this.props.checkStrictly = true;
      } else {
        this.props.checkStrictly = false;
      }

      this.isMatch = true;
      this.dialogVisible = true;
      this.createDate.id = row.id;
    },
    handleClose(done) {
      this.createDate = {
        id: "",
        systemValue: "",
      };
      this.systemValueSel = "";
      this.isEdit = false;
      this.isMatch = false;
      this.isallMatch = false;
      this.dialogVisible = false;
      this.dialogVisible1 = false;
      this.options.map((i) => {
        i.disabled = true;
      });
    },
    handleClose1(done) {
      this.drawerVisible = false;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      if (this.activeName == "1") {
        this.module = "";
        this.systemValue = "";
        this.loadList(false);
      }
      if (this.activeName == "2") {
        this.loadList(true);
      }
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      if (this.activeName == "1") {
        this.module = "";
        this.systemValue = "";
        this.loadList(false);
      }
      if (this.activeName == "2") {
        this.loadList(true);
      }
    },
    loadCityCloumn() {
      cityTree().then((res) => {
        if (res.success) {
          let cityList = [];
          cityList = res.result;
          this.options.forEach((item) => {
            if (item.name == "城市") {
              item.children = cityList;
            }
          });
        }
      });
    },
    loadInCloumn() {
      getCommonColumnList({ type: "行业" }).then((res) => {
        if (res.success) {
          let InList = [];
          InList = res.result;
          this.options.forEach((item) => {
            if (item.name == "行业") {
              item.children = InList;
            }
          });
        }
      });
    },
    loadPosCloumn() {
      getCommonColumnList({ type: "职能" }).then((res) => {
        if (res.success) {
          let PosList = [];
          PosList = res.result;
          this.options.forEach((item) => {
            if (item.name == "职能") {
              item.children = PosList;
            }
          });
        }
      });
    },
    // 搜索
    serchButton() {
      this.pagination.pageNo = 1;
      this.loadList(false);
    },
    loadList(type) {
      window.scrollTo(0, 0);
      this.listLoading = true;
      if (type) {
        this.filter.model = "";
      }
      resumeValueMatchlist({
        handled: type,
        matchValue: this.filter.name,
        module: this.filter.model,
        systemValue: this.systemValue,
        current: this.pagination.pageNo,
        size: this.pagination.pageSize,
        searchCount: true,
      }).then((res) => {
        if (res.success) {
          this.listLoading = false;
          if (type == true) {
            this.ListDataed = res.result.records;
          } else {
            this.ListData = res.result.records;
          }
          this.pagination.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    handleSelectionChange(val) {
      // this.multipleSelection = val;
      this.itemIds = val.map((i) => i.id);
    },
    handleResumeBatchMatch() {
      //  this.options.map((i) => {
      //   i.disabled = true;
      // });
      if (this.options[0].name == "全部") {
        this.options.splice(0, 1);
      }
      this.isallMatch = true;
      this.dialogVisible = true;
      // resumeBatchMatch({
      //   matchValue: this.filter.name,
      //   ids: this.itemIds,
      //   systemValue: this.systemValue,
      // }).then((res) => {
      //   if (res.success) {
      //     this.$message.success("操作成功");
      //     this.loadList(false);
      //   }
      // });
    },
    getfater(val) {
      this.matchValue = val[0];
    },
    deletMatch(val) {
      let ids = [];
      if (val.id) {
        ids = [val.id];
      } else {
        ids = this.itemIds;
      }
      this.$confirm("是否删除选中数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          deleteResumeValueMatch(ids).then((res) => {
            if (res.success) {
              this.pagination.pageNo = 1;
              this.pagination.pageSize = 50;
              this.loadList(false);
            }
          });
        })
        .catch(() => {});
      //  deleteResumeValueMatch({ ids: ids }).then((res) => {
      //   if (res.success) {
      //     let InList = [];
      //     InList = res.result;
      //     this.options.forEach((item) => {
      //       if (item.name == "行业") {
      //         item.children = InList;
      //       }
      //     });
      //   }
      // });
    },
    showDtialDig(val) {
      this.resumeDataMsg.moduleName = val.module;
      this.resumeDataMsg.matchValue = val.matchValue;
      deleteResumeValueMatchResumes({
        matchValue: val.matchValue,
        module: val.module,
        resumeValueMatchId: val.id,
      }).then((res) => {
        if (res.success) {
          this.resumeData = res.result;
        }
      });
      this.dialogVisible1 = true;
    },
    showReasumDig(id) {
      this.resumeDetailId = id;
      this.drawerVisible = true;
    },
  },
  created() {
    this.loadList(false);
    this.loadCityCloumn();
    this.loadInCloumn();
    this.loadPosCloumn();
  },
};
</script>
<style lang="scss">
.resumeTag {
  .el-tabs__nav-scroll {
    padding: 0 15px;
  }
  .el-form {
    padding: 0 15px;
    .el-form-item {
      display: flex;
      float: left;
      margin-bottom: 15px;
      .el-form-item__content {
        flex: 1;
      }
    }
  }
  .tabBorder {
    margin: 0 0 15px 0;
  }
  .el-pagination {
    margin-bottom: 10px;
    padding-right: 10px;
  }
}
.matchDialog {
  .el-dialog__body {
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
}
.seleKey {
  position: absolute;
  z-index: 999;
  width: 75px;
  margin-top: 49px;
  margin-left: 15px;
  line-height: 38px;
  input::-webkit-input-placeholder {
    color: #666 !important;
  }
  input::-moz-input-placeholder {
    color: #666 !important;
  }
  input::-ms-input-placeholder {
    color: #666 !important;
  }
  .el-input .el-input__inner {
    height: 28px !important;
  }
}
.matchList {
  .el-icon-plus {
    border: 1px solid #e5e5e5;
    padding: 5px;
    border-radius: 4px;
    margin-left: 10px;
    &:hover {
      color: #e60012;
      border-color: #e60012;
    }
  }
  .el-icon-close {
    margin-left: 10px;
    &:hover {
      color: #e60012;
    }
  }
}
.el-tooltip__popper.is-dark,
.el-tooltip__popper.is-light {
  max-width: 300px;
}
</style>